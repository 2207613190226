import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/Seo';
import StructuredData from '../components/structured-data'
import CardHorizontalPost from '../components/cards/card-horizontal-post'
import SubscribeWidget from '../components/subscribe-widget/subscribe-widget'
import CategoryWidget from '../components/category-widget/category-widget'
import ReferFriendWidget from '../components/refer-friend-widget/refer-friend-widget'
import Pagination from '../components/pagination/pagination'
import SearchForm from '../components/search/search-form'

const TagTemplate: React.FC<TagTemplateProps> = ({ data }) => {
  const {
    tag,
    posts: { nodes, pageInfo },
  } = data

  const { hasNextPage, hasPreviousPage, currentPage, pageCount } = pageInfo

  const post = {
    ...tag,
    slug: tag.uri.endsWith('/') ? tag.uri.slice(0, -1) : tag.uri,
  }

  return (
    <Layout>
      <Seo post={post} />
      <StructuredData pageType="categoriesTags" post={post} />
      <SearchForm />
      <div className="container py-10 lg:py-20 text-center">
        <p className="text-base text-gray-500 mb-3">TAG</p>
        <div className="p-5" style={{ backgroundColor: '#f7f7f7' }}>
          <h1 className="text-gray-700 mb-3">{tag.name}</h1>
          <p
            className="text-base font-normal"
            dangerouslySetInnerHTML={{ __html: tag.description }}
          />
        </div>
      </div>

      <div className="container lg:flex md:flex">
        <div className="lg:w-3/4 pb-10 lg:pr-40 md:pr-20">
          {nodes &&
            nodes.map(node => <CardHorizontalPost key={node.id} {...node} />)}
          <Pagination
            page={tag.uri}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            currentPage={currentPage}
            pageCount={pageCount}
            className="container text-center"
          />
        </div>

        <div className="lg:w-1/4">
          <SubscribeWidget />
          <CategoryWidget className="mt-10" />
          <ReferFriendWidget className="my-10 text-center" />
        </div>
      </div>
    </Layout>
  )
}

type TagTemplateProps = {
  location: Record<string, unknown>
  data: {
    tag: {
      name: string
      description: string
      uri: string
      seo: any
    }
    posts: {
      nodes: Array<{
        id: string
        title: string
        link: string
        excerpt: string
        seo: {
          description?: string
        }
        categories: {
          nodes?: Array<{
            name: string
            link: string
          }>
        }
        tags: {
          nodes?: Array<{
            name: string
            link: string
          }>
        }
      }>
      pageInfo: {
        hasNextPage: boolean
        hasPreviousPage: boolean
        currentPage: number
        pageCount: number
      }
    }
  }
}

export const pageQuery = graphql`
  query GET_TAGS($id: String!, $offset: Int!, $perPage: Int!) {
    tag: wpTag(id: { eq: $id }) {
      name
      description
      uri
      seo {
        ...taxonomySeoFields
      }
    }
    posts: allWpPost(
      filter: { tags: { nodes: { elemMatch: { id: { eq: $id } } } } }
      limit: $perPage
      skip: $offset
    ) {
      nodes {
        id
        title
        uri
        excerpt
        seo {
          metaDesc
        }
        categories {
          nodes {
            name
            uri
          }
        }
        tags {
          nodes {
            name
            uri
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
  }
`

export default TagTemplate
