import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

function CardHorizontalPost({ title, uri, seo, categories, tags }) {
  return (
    <div className="pb-10 mb-10 border-b border-gray-200">
      <h2 className="text-base md:text-xl leading-6 md:leading-8 mb-3">
        <Link
          to={uri}
          dangerouslySetInnerHTML={{ __html: title }}
          className="text-gray-900"
        />
      </h2>
      <div className="mb-4 md:mb-8">
        {categories && categories.nodes[0] && (
          <Link to={categories.nodes[0].uri}>{categories.nodes[0].name}</Link>
        )}
      </div>
      {seo && (
        <div className="text-grey-darker text-sm md:text-base mb-4 md:mb-8">
          {seo.metaDesc}
        </div>
      )}
      {tags && tags.nodes[0] && (
        <div className="pt-2 mb-6 text-sm text-gray-700">
          Tagged with:{' '}
          {tags.nodes.map((tag, i, array) => (
            <Link key={tag.name} to={tag.uri}>
              {tag.name}
              {i < array.length - 1 ? ', ' : null}
            </Link>
          ))}
        </div>
      )}
      <Link to={uri}>Continue Reading</Link>
    </div>
  )
}

CardHorizontalPost.propTypes = {
  title: PropTypes.string,
  uri: PropTypes.string,
  metaDesc: PropTypes.string,
  categories: PropTypes.object,
  tags: PropTypes.object,
}

export default CardHorizontalPost
